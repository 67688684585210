<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 예산년도 -->
          <c-datepicker
            label="재해년도"
            name="year"
            type="year"
            default="today"
            v-model="searchParam.costlossYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            label="사고자부서"
            name="deptCd"
            :isFirstValue="false"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            :comboItems="typeItems"
            itemText="codeName"
            itemValue="code"
            label="공상/산재"
            name="accidentType"
            v-model="searchParam.accidentType">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 연간 예산편성 목록 -->
    <c-table
      ref="table"
      title="손실비용 현황"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 등록 -->
          <c-btn label="LBLREG" v-if="editable" icon="add" @btnClicked="linkClick" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customBottomRow>
        <q-tr 
          v-if="grid.data && grid.data.length > 0" 
          no-hover
          class="table-bottom-fixed">
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">합계
          </q-td>
          <q-td
            class="text-right th-td-style th-style"
            :rowspan="1" :colspan="1">
            <span>
              {{ $_.sumBy(grid.data, 'nursingCount') | toThousandFilter }}
            </span>
          </q-td>
          <q-td
            class="text-right th-td-style th-style"
            :rowspan="1" :colspan="1">
            <span>
              {{ $_.sumBy(grid.data, 'hospitalCost') | toThousandFilter }}
            </span>
          </q-td>
          <q-td
            class="text-right th-td-style th-style"
            :rowspan="1" :colspan="1">
            <span>
              {{ $_.sumBy(grid.data, 'wage') | toThousandFilter }}
            </span>
          </q-td>
          <q-td
            class="text-right th-td-style th-style"
            :rowspan="1" :colspan="1">
            <span>
              {{ $_.sumBy(grid.data, 'costlossCost') | toThousandFilter }}
            </span>
          </q-td>
          <q-td
            class="text-right th-td-style th-style"
            :rowspan="1" :colspan="1">
            <span>
              {{ $_.sumBy(grid.data, 'costlossCostTotal') | toThousandFilter }}
            </span>
          </q-td>
        </q-tr>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-health-year-budget',
  data() {
    return {
      typeItems: [ 
        { code: '공상', codeName: '공상' },
        { code: '산재', codeName: '산재' },
      ],
      grid: {
        columns: [
          
          {
            name: 'accidentTypeName',
            field: 'accidentTypeName',
            label: '재해구분',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'accidentType',
            field: 'accidentType',
            label: '공상/산재',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'accidentName',
            field: 'accidentName',
            label: '사고명',
            align: 'left',
            type: 'link',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '사고자부서',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'accidentUserName',
            field: 'accidentUserName',
            label: '사고자',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'regulName',
            field: 'regulName',
            label: '정규/임시직',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'accidentStartDate',
            field: 'accidentStartDate',
            label: '요양시작일',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'accidentEndDate',
            field: 'accidentEndDate',
            label: '복귀일',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'nursingCount',
            field: 'nursingCount',
            label: '휴업일수<br/>(주말제외)',
            type: 'cost',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'hospitalCost',
            field: 'hospitalCost',
            label: '병원진료비<br/>(요양비)[A]',
            type: 'cost',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'wage',
            field: 'wage',
            label: '장해보상금<br/>[B]',
            type: 'cost',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'costlossCost',
            field: 'costlossCost',
            label: '근로손실비<br/>[C]',
            style: 'width:140px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'costlossCostTotal',
            field: 'costlossCostTotal',
            label: '합계손실액<br/>[A+B+C]',
            type: 'linkcost',
            style: 'width:140px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        costlossYear: '',
        deptCd: '',
        accidentTypeCd: null,
        accidentType: null,
      },
      editable: true,
      listUrl: '',
      detailUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sai.costloss.list.url;
      this.detailUrl = selectConfig.sai.costloss.get.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      if (col.name == 'accidentName') {
        this.popupOptions.title = "사고 상세";
        this.popupOptions.param = {
          iimAccidentId: row ? row.iimAccidentId : '',
          stepCd: row ? row.accidentStatusCd : '',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/iim/processAccidentDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.isFull = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else if (col.name == 'costlossCostTotal') {
        this.popupOptions.title = "HR 월별 임금정보";
        this.popupOptions.param = {
          iimAccidentId: row ? row.iimAccidentId : '',
        };
        this.popupOptions.target = () => import(`${'@/pages/sai/costloss/costlossManageDetail.vue'}`);
        this.popupOptions.width = "70%"
        this.popupOptions.visible = true;
        this.popupOptions.isFull = false;
        this.popupOptions.closeCallback = this.closePopup;
      }
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
