var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: { name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-datepicker", {
                  attrs: {
                    label: "재해년도",
                    name: "year",
                    type: "year",
                    default: "today",
                  },
                  model: {
                    value: _vm.searchParam.costlossYear,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "costlossYear", $$v)
                    },
                    expression: "searchParam.costlossYear",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-dept", {
                  attrs: {
                    type: "search",
                    label: "사고자부서",
                    name: "deptCd",
                    isFirstValue: false,
                  },
                  model: {
                    value: _vm.searchParam.deptCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "deptCd", $$v)
                    },
                    expression: "searchParam.deptCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    type: "search",
                    comboItems: _vm.typeItems,
                    itemText: "codeName",
                    itemValue: "code",
                    label: "공상/산재",
                    name: "accidentType",
                  },
                  model: {
                    value: _vm.searchParam.accidentType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "accidentType", $$v)
                    },
                    expression: "searchParam.accidentType",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "손실비용 현황",
            columns: _vm.grid.columns,
            gridHeight: _vm.grid.height,
            data: _vm.grid.data,
            columnSetting: false,
            expandAll: true,
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customBottomRow",
              fn: function () {
                return [
                  _vm.grid.data && _vm.grid.data.length > 0
                    ? _c(
                        "q-tr",
                        {
                          staticClass: "table-bottom-fixed",
                          attrs: { "no-hover": "" },
                        },
                        [
                          _c("q-td", {
                            staticClass: "text-center th-td-style th-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          }),
                          _c("q-td", {
                            staticClass: "text-center th-td-style th-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          }),
                          _c("q-td", {
                            staticClass: "text-center th-td-style th-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          }),
                          _c("q-td", {
                            staticClass: "text-center th-td-style th-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          }),
                          _c("q-td", {
                            staticClass: "text-center th-td-style th-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          }),
                          _c("q-td", {
                            staticClass: "text-center th-td-style th-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          }),
                          _c("q-td", {
                            staticClass: "text-center th-td-style th-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          }),
                          _c(
                            "q-td",
                            {
                              staticClass: "text-center th-td-style th-style",
                              attrs: { rowspan: 1, colspan: 1 },
                            },
                            [_vm._v("합계 ")]
                          ),
                          _c(
                            "q-td",
                            {
                              staticClass: "text-right th-td-style th-style",
                              attrs: { rowspan: 1, colspan: 1 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toThousandFilter")(
                                        _vm.$_.sumBy(
                                          _vm.grid.data,
                                          "nursingCount"
                                        )
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "q-td",
                            {
                              staticClass: "text-right th-td-style th-style",
                              attrs: { rowspan: 1, colspan: 1 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toThousandFilter")(
                                        _vm.$_.sumBy(
                                          _vm.grid.data,
                                          "hospitalCost"
                                        )
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "q-td",
                            {
                              staticClass: "text-right th-td-style th-style",
                              attrs: { rowspan: 1, colspan: 1 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toThousandFilter")(
                                        _vm.$_.sumBy(_vm.grid.data, "wage")
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "q-td",
                            {
                              staticClass: "text-right th-td-style th-style",
                              attrs: { rowspan: 1, colspan: 1 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toThousandFilter")(
                                        _vm.$_.sumBy(
                                          _vm.grid.data,
                                          "costlossCost"
                                        )
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "q-td",
                            {
                              staticClass: "text-right th-td-style th-style",
                              attrs: { rowspan: 1, colspan: 1 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toThousandFilter")(
                                        _vm.$_.sumBy(
                                          _vm.grid.data,
                                          "costlossCostTotal"
                                        )
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLREG", icon: "add" },
                        on: { btnClicked: _vm.linkClick },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    attrs: { label: "LBLSEARCH", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }